@import '../../utils/styles/index';

// .modal_container{
//   position: fixed;
//   z-index: 1000;
//   left: 0;
//   top: 0;
//   width: 100%;
//   height: 100%;
//   overflow: auto;
//   background-color: rgba(57, 63, 77, 0.5);
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   &__body{
//     background-color: white;
//     width: 500px;
//     height: 205.8px;
//     border-radius: 15px;
//     display: flex;
//     flex-direction: column;
//     justify-content: space-around;
//     position: relative;
//   }
// }

.modal {
  width: 100%;
  @include media(md) {
    max-width: 75%;
    margin: 0 auto;
  }
  &-wrapper {
    // position: fixed;
    // width: 100%;
    // height: 100vh;
    // top: 0;
    // left: 0;
    // right: 0;
    // bottom: 0;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // padding: 0 1rem;
    // margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.05);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 6;
    opacity: 1;
    transition: opacity 0.2s ease;
    pointer-events: all;
    overflow-y: auto;

    background-color: rgba(0, 0, 0, 0.9);
    z-index: 6;

    @include media(md) {
      padding: 0 2rem;
    }
  }
  &__close {
    margin: 0 0 0 auto;
    display: block;
    z-index: -1;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
  }
}
