@use '../../../utils/styles/default.scss';
@import '../../../utils/styles/mixin.scss';
@import '../../../utils/styles/colors.scss';

.chest {
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 30vw;
  @include media(md) {
    max-width: 8vw;
  }
  @include media(xxxl) {
    max-width: 5vw;
  }
  &__grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    width: 100%;
    > div {
      padding-bottom: 5%;
    }
  }
  &__img {
    width: 100%;
    aspect-ratio: 1 / 1;
    cursor: url('../../../assets/icons/money-bag.png') 1.5 1.5, auto;
  }
  &-section {
    &-wrapper {
      padding: 3% 0 0;
      background-image: linear-gradient(0deg, rgb(25, 25, 29), rgba(25, 25, 29, 0));
    }
  }
  &__row {
    position: relative;
    margin-bottom: 2%;
    .nft-item {
      display: flex;
      align-items: flex-start;
      text-align: left;
      font-size: 1vw;
      padding-right: 0.5rem;
      padding-bottom: 8%;
    }
    .nft-image {
      margin-right: 0.3rem;
      text-align: center;
    }
    .button-next,
    .button-prev {
      position: absolute;
      top: 50%;
      height: 8vw;
      padding: 0 1vw;
      width: 4vw;
      transition: 0.2s ease opacity;
      &:disabled {
        display: none;
      }
      &:hover {
        opacity: 0.8;
      }
      img {
        width: 100%;
      }
    }
    .button-prev {
      left: 0;
      transform: translateY(-50%) translateX(-100%);
      outline: none;
      border: none;
      background-color: transparent;
    }
    .button-next {
      right: 0;
      transform: translateY(-50%) translateX(100%);
      outline: none;
      border: none;
      background-color: transparent;
    }
  }
  &__row ~ .btn-outline {
    margin-top: 2vw;
  }
  &__empty-text {
    font-size: 1.5vw;
    height: 12vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.nft-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  font-size: 0.8vw;
  .nft-image {
    width: 3.5vw;
    height: 3.5vw;
    margin-right: 0;
    margin-bottom: 0.2vw;
  }
  &__item {
    margin-bottom: 0.8vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.modal-map-table.chest-table {
  .swiper {
    padding: 2% 0;
    margin-bottom: 2%;
  }
  .swiper-wrapper {
    padding: 0 1rem;
    user-select: none;
  }
}

.chest__row .swiper .swiper-slide {
  text-align: center;
  font-size: 1.1vw;
  flex-shrink: 0;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
